import { v4 as uuidv4 } from 'uuid';

import { getCookie, setCookie } from 'JIX/utils/cookies.js';
import { api } from 'JIX/utils.js';

const elementHandlers = {
    IMAGE(elem) {
        if (elem.getAttribute('data-src')) {
            elem.src = elem.getAttribute('data-src');
            elem.removeAttribute('data-src');
        }
    },
    IFRAME(elem) {
        if (elem.getAttribute('data-src')) {
            elem.src = elem.getAttribute('data-src');
            elem.removeAttribute('data-src');
        }
    },
};

class InvalidCategoryError extends Error {
    constructor(category) {
        super(`Invalid category '${category}'`);
        this.category = category;
        this.name = 'InvalidCategoryError';
    }
}

class CookieConsent {
    constructor({ categories, cookieOptions, urls, options, currentVersion, latestTermsVersion, headerConsent }) {
        this._categories = categories;
        this._cookieOptions = cookieOptions;
        this._urls = urls;
        this._options = options;
        this._currentVersion = currentVersion;
        this._latestTermsVersion = latestTermsVersion;
        this._headerConsent = headerConsent;
        this._currentConsentId = null;

        this._catMap = categories.reduce((acc, cur) => {
            acc[cur.key] = cur;
            return acc;
        }, {});

        this._unresolvedPromises = [];
    }

    get categories() {
        return this._categories;
    }

    get isDnt() {
        return !!(navigator.doNotTrack && navigator.doNotTrack === "1");
    }

    hasConsent(category) {
        return new Promise((resolve, reject) => {
            if (!this._catMap[category]) {
                reject(new InvalidCategoryError(category));
            }
            if (this._catMap[category].required) {
                resolve(true);
            } else {
                const data = this.getConsent(true);
                if (!data && this.isDnt) {
                    resolve(false);
                } else {
                    this._unresolvedPromises.push({ category, resolve });
                    if (data) {
                        this._resolvePromises(data);
                    }
                }
            }
        });
    }

    whenConsent(category, callback) {
        if (!this._catMap[category]) {
            throw new InvalidCategoryError(category);
        }
        this.hasConsent(category)
            .then(consented => {
                if (consented) {
                    callback();
                }
            });
    }

    getConsent(requireCurrent = false) {
        const res = this._headerConsent || getCookie(this._cookieOptions.name, { json: true }) || null;
        return !requireCurrent || (res && res.version >= this._currentVersion && res.terms_version >= this._latestTermsVersion) ? res : null;
    }

    _getConsentId() {
        if (!this._currentConsentId) {
            const currentConsent = this.getConsent() || {};
            this._currentConsentId = currentConsent.id ? currentConsent.id : uuidv4();
        }

        return this._currentConsentId;
    }

    setConsent(consent) {
        const data = {
            id: this._getConsentId(),
            time: new Date().toISOString(),
            version: this._currentVersion,
            terms_version: this._latestTermsVersion,
            consent
        };

        const hasConsentData = consent && Object.keys(consent).length !== 0;

        if (hasConsentData) {
            setCookie(this._cookieOptions.name, data, { ...this._cookieOptions, json: true });
        }

        return api('PUT', this._urls.logConsent, data, { json: true })
            .finally(() => {
                if (hasConsentData) {
                    window.dispatchEvent(new CustomEvent(this.UPDATE_EVENT_NAME, { detail: data.consent }));
                    this._resolvePromises(data);
                }
            });
    }

    setupElementHandler(selector) {
        const listener = () => {
            for (const elem of document.querySelectorAll(selector)) {
                const handler = elementHandlers[elem.tagName];
                if (!handler) {
                    console.error("No handler for " + elem.tagName);
                    continue;
                }

                const category = elem.dataset.jixConsent;
                const consent = this.getConsent(true);
                if (consent && consent.consent && consent.consent[category]) {
                    handler(elem);
                }
            }
        };

        listener();
        window.addEventListener(this.UPDATE_EVENT_NAME, listener);
    }

    shouldAskForConsent() {
        return !!(!this.isDnt && this._options.enableAutoOpen && !this.getConsent(true) && this._latestTermsVersion > 0);
    }

    _resolvePromises(data) {
        for (const { category, resolve } of this._unresolvedPromises) {
            resolve(category in data.consent ? data.consent[category] : null);
        }
        this._unresolvedPromises = [];
    }
}

Object.defineProperty(CookieConsent.prototype, 'UPDATE_EVENT_NAME', {
    value: "jix.cookie-consent.updated",
    writable: false,
});

export default CookieConsent;
