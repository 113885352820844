import 'icons/valid.svg';
import 'icons/invalid.svg';

import { __ } from 'JIX/gettext.js';

const DATE_FORMAT_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

function getLocale() {
    return document.querySelector('html').getAttribute('lang');
}

function getDateFormatter(locale = getLocale()) {
    return new Intl.DateTimeFormat(locale, DATE_FORMAT_OPTIONS);
}

export default class CookieConsentInfoBox {
    constructor(boxElement, cookieConsent) {
        this._box = boxElement;
        this._cookieConsent = cookieConsent;
    }

    update() {
        const box = this._box;
        const data = this._cookieConsent.getConsent();

        box.hidden = !data;
        if (!data) {
            return;
        }

        const infoCat  = box.querySelector('#jix-cookie-consent-info__categories');
        const infoDate = box.querySelector('#jix-cookie-consent-info__date');
        const infoId   = box.querySelector('#jix-cookie-consent-info__id');

        const date = new Date(data.time);
        date.setMilliseconds(0);

        const dateFormatted = getDateFormatter().format(date);

        for (const li of infoCat.querySelectorAll('li')) {
            const category = li.getAttribute('data-category');
            const consented = data.consent[category];

            const icon = consented ? 'valid' : 'invalid';
            const label = consented ? __('Valgt') : __('Fravalgt');

            const svg = li.querySelector('svg');

            svg.setAttribute('aria-label', label);
            svg.querySelector('use').setAttribute('xlink:href', '#svg-' + icon);
            svg.classList.toggle('jix-cookie-consent-icon--active', consented);
            svg.classList.toggle('jix-cookie-consent-icon--inactive', !consented);
        }

        infoId.innerText = data.id;

        infoDate.setAttribute('datetime', date.toISOString());
        infoDate.innerText = dateFormatted;
    }
}
