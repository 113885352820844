import pageLoaded from 'JIX/utils/pageLoaded.js';

import CookieConsent from 'JIX/cookie_consent/CookieConsent.js';
import CookieConsentModal from 'JIX/cookie_consent/CookieConsentModal.js';
import CookieConsentInfoBox from 'JIX/cookie_consent/CookieConsentInfoBox.js';

const JIX = window.JIX = window.JIX || {};

const stash = window.Stash['cookie_consent'];
JIX.CookieConsent = new CookieConsent(stash);

function setupModal(modalElement) {
    JIX.CookieConsentModal = new CookieConsentModal(modalElement, JIX.CookieConsent);

    if (JIX.CookieConsent.shouldAskForConsent()) {
        JIX.CookieConsentModal.show();
    }

    for (const elem of document.querySelectorAll('.jix-cookie-consent-link')) {
        elem.addEventListener('click', (event) => {
            event.preventDefault();
            JIX.CookieConsentModal.show({ allowDismiss: true });
        });
    }

    window.addEventListener('message', (event) => {
        if (event.origin === window.location.origin && event.data === 'jix-show-cookie-consent-modal') {
            JIX.CookieConsentModal.show({ allowDismiss: true });
        }
    });
}

function setupInfoBox(boxElement) {
    const box = new CookieConsentInfoBox(boxElement, JIX.CookieConsent);
    window.addEventListener(JIX.CookieConsent.UPDATE_EVENT_NAME, () => box.update());
}

function main() {
    if (stash.options.enableBox) {
        let modal;
        if (stash.options.sandbox) {
            const modalElem = document.createElement('jix-cookie-consent-modal');
            document.body.appendChild(modalElem);
            modal = modalElem.modal;
        } else {
            modal = document.getElementById('jix-cookie-consent-modal');
        }
        if (modal) {
            setupModal(modal);
        }
    }

    const infoBox = document.getElementById('jix-cookie-consent-info');
    if (infoBox) {
        setupInfoBox(infoBox);
    }

    JIX.CookieConsent.setupElementHandler('[data-jix-consent]');
}

pageLoaded().then(main);
