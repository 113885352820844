export function getCookies() {
    return Object.freeze(document.cookie
        .split(/\s*;\s*/)
        .reduce((acc, cur) => {
            if (cur) {
                const [name, value] = cur.split('=', 2).map(x => decodeURIComponent(x));
                acc[name] = value;
            }
            return acc;
        }, {}));
}

export function getCookie(name, options = {}) {
    const res = getCookies()[name];
    return res && options.json ? JSON.parse(res) : res;
}

export function setCookie(name, value, options = {}) {
    const parts = [encodeURIComponent(name) + '=' + encodeURIComponent(options.json ? JSON.stringify(value) : value)];
    if (options.maxAge) {
        parts.push("max-age=" + options.maxAge);
    }
    if (options.domain) {
        parts.push("domain=" + options.domain);
    }
    if (options.path) {
        parts.push("path=" + options.path);
    }
    if (options.samesite) {
        parts.push("samesite=" + options.samesite);
    }
    if (options.secure) {
        parts.push("secure");
    }

    document.cookie = parts.join('; ');
}
