class CookieConsentModalElement extends HTMLElement {
    constructor() {
        super();

        const template = document.getElementById('jix-cookie-consent-template');
        if (!template) {
            return;
        }

        const templateContent = template.content;
        this._shadowRoot = this.attachShadow({ mode: 'closed' });
        for (const cssFile of window.Stash.cookie_consent.options.modalCss) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = cssFile;
            this._shadowRoot.appendChild(link);
        }
        this._shadowRoot.appendChild(templateContent.cloneNode(true));
    }

    get modal() {
        return this._shadowRoot.getElementById('jix-cookie-consent-modal');
    }
}

customElements.define('jix-cookie-consent-modal', CookieConsentModalElement);

export default class CookieConsentModal {
    constructor(modalElement, cookieConsent) {
        this._modalElement = modalElement;
        this._cookieConsent = cookieConsent;
        this._checkboxes = [...modalElement.querySelectorAll('input[type=checkbox]')];

        modalElement.querySelector('#jix-cookie-consent-accept-selected')
            .addEventListener('click', () => this._modalSubmit(false));
        modalElement.querySelector('#jix-cookie-consent-accept-all')
            .addEventListener('click', () => this._modalSubmit(true));

        this._checkboxes.forEach((elem) => {
            elem.addEventListener('click', () => this._updateButtonText());
        });

        this._intro = modalElement.querySelector('.jix-cookie-consent-modal__intro');
        this._intro.addEventListener('scroll', () => this._updateIntro(), { passive: true });

        for (const closeBtn of modalElement.querySelectorAll('[data-dismiss=modal]')) {
            closeBtn.addEventListener('click', () => this._setVisibility(false));
        }
    }

    show(options = {}) {
        const allowDismiss = options.allowDismiss || false;

        if (!allowDismiss) {
            this._cookieConsent.setConsent({});
        }

        this._modalElement.querySelector('.close').hidden = !allowDismiss;

        const consent = this._cookieConsent.getConsent(true);
        for (const category of this._cookieConsent.categories) {
            const elem = this._modalElement.querySelector(`#jix-cookie-consent__${category.key}`);
            elem.disabled = category.required;
            if (category.required) {
                elem.checked = true;
            } else if (consent) {
                elem.checked = !!consent.consent[category.key];
            } else {
                elem.checked = false;
            }
        }

        this._updateButtonText();
        this._setVisibility(true);
        this._updateIntro();
    }

    _modalSubmit(all) {
        const data = {};
        for (const category of this._cookieConsent.categories) {
            let consent = false;
            if (all || category.required) {
                consent = true;
            } else {
                const elem = this._modalElement.querySelector(`#jix-cookie-consent__${category.key}`);
                consent = elem && elem.checked;
            }
            data[category.key] = consent;
        }

        this._cookieConsent.setConsent(data);
        this._setVisibility(false);
    }

    _updateButtonText() {
        const numRequired = this._checkboxes.filter(x => x.disabled).length;
        const numChecked = this._checkboxes.filter(x => x.checked).length;
        const onlyNecessary = numChecked <= numRequired;

        this._modalElement.querySelector('.jix-cookie-consent-accept-selected__chosen').hidden = onlyNecessary;
        this._modalElement.querySelector('.jix-cookie-consent-accept-selected__necessary').hidden = !onlyNecessary;
    }

    _updateIntro() {
        const isBottom = this._intro.scrollHeight - this._intro.scrollTop - this._intro.clientHeight <= 0;
        this._intro.classList.toggle('has-scroll', !isBottom);
    }

    _setVisibility(shown) {
        const modal = this._modalElement;

        document.body.classList.toggle('modal-open', shown);
        modal.classList.toggle('show', shown);

        const htmlStyle = document.querySelector('html').style;

        if (shown) {
            modal.removeAttribute('aria-hidden');
            modal.setAttribute('aria-modal', true);

            this._backdrop = document.createElement('div');
            this._backdrop.classList.add('modal-backdrop', 'show');
            document.body.appendChild(this._backdrop);

            // If we are sandboxed in a shadow DOM, rem unfortunately still
            // refers to the real root instead of the shadow root.
            //
            // We'll overrule it and hide the fact that we are messing with it
            // by using a blur backdrop instead of a semi-transparent backdrop
            if (window.Stash.cookie_consent.options.sandbox) {
                const bs = this._backdrop.style;
                bs.zIndex = 100000;
                bs.position = 'fixed';
                bs.left = bs.top = bs.right = bs.bottom = 0;
                bs.width = '100vw';
                bs.height = '100vh';
                if (CSS.supports('backdrop-filter', 'blur(25px)')) {
                    bs.opacity = 1;
                    bs.background = 'transparent';
                    bs.backdropFilter = 'blur(25px)';
                } else {    // Only unsupported in Firefox... this will have to do
                    bs.background = '#000';
                    bs.opacity = .8;
                }

                modal.style.zIndex = 200000;
                modal.style.overflowY = 'auto';

                this._oldRootFontSize = htmlStyle.fontSize;
                htmlStyle.fontSize = '16px';
            }
        } else {
            if (this._oldRootFontSize !== undefined) {
                htmlStyle.fontSize = this._oldRootFontSize;
                this._oldRootFontSize = null;
            }

            modal.setAttribute('aria-hidden', true);
            modal.removeAttribute('aria-modal');

            if (this._backdrop) {
                this._backdrop.remove();
                this._backdrop = null;
            }
        }

        const siteWrapper = document.querySelector('.site-wrapper');
        if (siteWrapper) {
            siteWrapper.inert = shown;
        }
    }
}
